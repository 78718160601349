import { call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../../constants/index'
import { fetchJSON } from '../../utils'

import {
  deleteMediaConverterFailure,
  deleteMediaConverterSuccess,
  fetchStatisticsFailure,
  fetchStatisticsSuccess,
  fetchUsageInfoFailure,
  fetchUsageInfoSuccess,
  getMediaConverterFailure,
  getMediaConverterSuccess
} from '../actions/file_list'

function * fetchUsage () {
  const requestData = {
    method: 'get'
  }

  try {
    // const { data } = yield call(fetchJSON, '/usage/', requestData)
    yield put(fetchUsageInfoSuccess({
      code: 200,
      current_convert_daily: 0,
      current_storage: 0,
      last_convert: null,
      total_convert_daily: 20,
      total_file: 0,
      total_storage: 10737418240
    }))
  } catch (error) {
    yield put(fetchUsageInfoFailure(error.data))
  }
}

function * fetchStatistics () {
  try {
    yield put(fetchStatisticsSuccess({
      code: 200,
      total_file: 48258807,
      total_file_size: 96536235000000
    }))
  } catch (error) {
    yield put(fetchStatisticsFailure(error.data))
  }
}

function * getMediaConverter ({ payload: { ids, page, page_size }}) {
  const requestData = {
    method: 'get'
  }

  const url = ids ? `/converter/conversions?session_convert=${ids}&page=${page}&page_size=${page_size}` : `/converter/conversions?page=${page}&page_size=${page_size}`
  try {
    const { data } = yield call(fetchJSON, url, requestData)
    yield put(getMediaConverterSuccess(data))
  } catch (error) {
    console.log(error)
    yield put(getMediaConverterFailure(error.data))
  }
}

function * deleteMediaConverter ({payload}) {
  try {
    const { data } = yield call(fetchJSON,`/media-converter/${payload.media_id || payload.id}/`, {method: 'delete'})
    yield put(deleteMediaConverterSuccess(`${data}_${payload.media_id || payload.id}/`))
  } catch (error) {
    yield put(deleteMediaConverterFailure(error.data))
  }
}


function * watchFetchUsage () {
  yield takeLatest(actionTypes.FETCH_USAGE_REQUEST, fetchUsage)
}

function * watchFetchStatistics () {
  yield takeLatest(actionTypes.FETCH_STATISTICS_REQUEST, fetchStatistics)
}

function * watchGetMediaConverter () {
  yield takeLatest(actionTypes.FETCH_MEDIA_CONVERTER_REQUEST, getMediaConverter)
}

function * watchDeleteMediaConverter () {
  yield takeLatest(actionTypes.DELETE_MEDIA_CONVERTER, deleteMediaConverter)
}

export { watchFetchUsage,  watchFetchStatistics, watchGetMediaConverter, watchDeleteMediaConverter }
