import { call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../../constants/index'
import { fetchJSON } from '../../utils'

import { requestContactUsFailure, requestContactUsSuccess } from '../actions/contact'

function * requestContactUs ({ payload }) {
  const requestData = {
    payload,
    method: 'post'
  }

  try {
    const { data } = yield call(fetchJSON, '/contact/contact-messages', requestData)
    yield put(requestContactUsSuccess(data))
  } catch (error) {
    yield put(requestContactUsFailure(error.data))
  }
}

function * watchRequestContactUs () {
  yield takeLatest(actionTypes.CONTACT_US_REQUEST, requestContactUs)
}

export { watchRequestContactUs }
