// ACTIONS
import * as actionTypes from '../../constants/index'

export const clearUserInfo = () => ({
  type: actionTypes.CLEAR_USER_INFO
})

export const updateUserPassword = (payload) => ({
  type: actionTypes.UPDATE_USER_PASSWORD_REQUEST,
  payload
})

export const updateUserPasswordSuccess = (payload) => ({
  type: actionTypes.UPDATE_USER_PASSWORD_SUCCESS,
  payload
})

export const updateUserPasswordFail = (error) => ({
  type: actionTypes.UPDATE_USER_NAME_FAIL,
  error
})

export const updateUserName = (payload) => ({
  type: actionTypes.UPDATE_USER_NAME_REQUEST,
  payload
})

export const updateUserNameSuccess = (payload) => ({
  type: actionTypes.UPDATE_USER_NAME_SUCCESS,
  payload
})

export const updateUserNameFail = (error) => ({
  type: actionTypes.UPDATE_USER_NAME_FAIL,
  error
})
export const updateUserEmail = (payload) => ({
  type: actionTypes.UPDATE_USER_EMAIL_REQUEST,
  payload
})

export const updateUserEmailSuccess = (payload) => ({
  type: actionTypes.UPDATE_USER_EMAIL_SUCCESS,
  payload
})

export const updateUserEmailFail = (error) => ({
  type: actionTypes.UPDATE_USER_EMAIL_FAIL,
  error
})

export const deleteUserAccount = (payload) => ({
  type: actionTypes.DELETE_USER_ACCOUNT_REQUEST,
  payload
})

export const deleteUserAccountSuccess = (payload) => ({
  type: actionTypes.DELETE_USER_ACCOUNT_SUCCESS,
  payload
})

export const deleteUserAccountFail = (error) => ({
  type: actionTypes.DELETE_USER_ACCOUNT_FAIL,
  error
})

export const getAccountSocials = (payload) => ({
  type: actionTypes.GET_ACCOUNT_SOCIALS_REQUEST,
  payload
})

export const getAccountSocialsSuccess = (payload) => ({
  type: actionTypes.GET_ACCOUNT_SOCIALS_SUCCESS,
  payload
})

export const getAccountSocialsFail = (error) => ({
  type: actionTypes.GET_ACCOUNT_SOCIALS_FAIL,
  error
})

export const deleteSocialAccount = (payload) => ({
  type: actionTypes.DELETE_SOCIAL_ACCOUNT_REQUEST,
  payload
})

export const deleteSocialAccountSuccess = (payload) => ({
  type: actionTypes.DELETE_SOCIAL_ACCOUNT_SUCCESS,
  payload
})

export const deleteSocialAccountFail = (error) => ({
  type: actionTypes.DELETE_SOCIAL_ACCOUNT_FAIL,
  error
})

export const fetchCloudStorage = (payload) => ({
  type: actionTypes.FETCH_CLOUD_STORAGE,
  payload
})

export const fetchCloudStorageSuccess = (payload) => ({
  type: actionTypes.FETCH_CLOUD_STORAGE_SUCCESS,
  payload
})

export const fetchCloudStorageFailure = (error) => ({
  type: actionTypes.FETCH_CLOUD_STORAGE_FAILURE,
  error
})