import * as actionTypes from '../../constants/index'

const initialState = {
  formats: { from: '', to: '' }
}
const FormatsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FORMATS: {
      return { ...state, formats: payload }
    }
    default:
      return state
  }
}

export default FormatsReducer
