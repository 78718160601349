import { call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../../constants/index'
import { fetchJSON } from '../../utils'
import {
  deleteSocialAccountFail,
  deleteSocialAccountSuccess,
  deleteUserAccountFail,
  deleteUserAccountSuccess,
  fetchCloudStorageFailure,
  fetchCloudStorageSuccess,
  getAccountSocialsFail,
  getAccountSocialsSuccess,
  updateUserEmailFail,
  updateUserEmailSuccess,
  updateUserNameFail,
  updateUserNameSuccess,
  updateUserPasswordFail,
  updateUserPasswordSuccess
} from '../actions/user'

function * updateUserPassword ({ payload }) {
  const { oldPassword, password, passwordConfirm, userId } = payload
  const requestData = {
    payload: {
      old_password: oldPassword,
      password,
      password_confirm: passwordConfirm
    },
    method: 'post'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/change-password/`,
      requestData
    )

    yield put(updateUserPasswordSuccess(data))
  } catch (error) {
    yield put(updateUserPasswordFail(error.data))
  }
}

function * updateUserName ({ payload }) {
  const { name, userId } = payload
  const requestData = {
    payload: { name },
    method: 'put'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/`,
      requestData
    )
    yield put(updateUserNameSuccess(data))
  } catch (error) {
    yield put(updateUserNameFail(error.data))
  }
}

function * updateUserEmail ({ payload }) {
  const { email, userId } = payload
  const requestData = {
    payload: { new_email: email },
    method: 'post'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/request-update-email/`,
      requestData
    )
    yield put(updateUserEmailSuccess(data))
  } catch (error) {
    yield put(updateUserEmailFail(error.data))
  }
}

function * deleteUserAccount ({ payload }) {
  const { userId } = payload
  const requestData = {
    method: 'post'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/request-delete/`,
      requestData
    )

    yield put(deleteUserAccountSuccess(data))
  } catch (error) {
    yield put(deleteUserAccountFail(error.data))
  }
}

function * deleteSocialAccount ({ payload }) {
  const { userId, socialId } = payload
  const requestData = {
    method: 'delete'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/socials/${socialId}/`,
      requestData
    )

    yield put(deleteSocialAccountSuccess(data))
  } catch (error) {
    yield put(deleteSocialAccountFail(error.response))
  }
}

function * getSocialAccount ({ payload }) {
  const { userId } = payload
  const requestData = {
    method: 'get'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/socials/`,
      requestData
    )

    yield put(getAccountSocialsSuccess(data.results))
  } catch (error) {
    yield put(getAccountSocialsFail(error.data))
  }
}

function * watchUpdateUserPassword () {
  yield takeLatest(
    actionTypes.UPDATE_USER_PASSWORD_REQUEST,
    updateUserPassword
  )
}

function * fetchCloudStorage ({payload}) {
  try {
    const { data } = yield call(fetchJSON, payload.url, {method: 'get'})
    yield put(fetchCloudStorageSuccess(data))
  } catch (error) {
    yield put(fetchCloudStorageFailure(error.data))
  }
}

function * watchDeleteSocialAccount () {
  yield takeLatest(actionTypes.DELETE_SOCIAL_ACCOUNT_REQUEST, deleteSocialAccount)
}

function * watchUpdateUserName () {
  yield takeLatest(actionTypes.UPDATE_USER_NAME_REQUEST, updateUserName)
}

function * watchUpdateUserEmail () {
  yield takeLatest(actionTypes.UPDATE_USER_EMAIL_REQUEST, updateUserEmail)
}

function * watchDeleteUserAccount () {
  yield takeLatest(actionTypes.DELETE_USER_ACCOUNT_REQUEST, deleteUserAccount)
}

function * watchGetSocialAccount () {
  yield takeLatest(actionTypes.GET_ACCOUNT_SOCIALS_REQUEST, getSocialAccount)
}

function* watchFetchCloudStorage () {
  yield takeLatest(actionTypes.FETCH_CLOUD_STORAGE, fetchCloudStorage)
}

export { watchUpdateUserPassword, watchUpdateUserName, watchDeleteUserAccount, watchGetSocialAccount, watchDeleteSocialAccount, watchUpdateUserEmail, watchFetchCloudStorage }
