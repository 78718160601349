import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import Head from 'next/head'
import withRedux from 'next-redux-wrapper'
import withReduxSaga from 'next-redux-saga'
import Router from 'next/router'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { RECAPTCHA_V3_CLIENT_KEY } from '../constants'
import Layout from '../components/Layout'
import initStore from '../redux/store'
// import { changeLanguage } from '../redux/actions/language'
import { createEvanoSID } from '../utils'
import 'public/style/index.less'
import 'public/style/app.sass'
import { DefaultSeo } from 'next-seo'
import SEO from 'next-seo.config'
import * as gtag from '../utils/analytics'
import { appWithTranslation } from 'next-i18next'

const EvanoApp = ({Component, pageProps, store}) => {
  // static async getInitialProps ({ Component, ctx }) {
  //   let pageProps = {}
  //   if (ctx.req && ctx.store) {
  //     if (ctx.req.language !== ctx.store.getState('language').languageCode) {
  //       ctx.store.dispatch(changeLanguage(ctx.req.language))
  //     }
  //   }
  //   if (Component.getInitialProps) {
  //     pageProps = await Component.getInitialProps(ctx)
  //   }

  //   return { pageProps }
  // }
  useEffect(() => {
    createEvanoSID()
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_CLIENT_KEY}>
      <Provider store={store}>
        <Head>
          <link rel="preconnect" href="https://fonts.googleapis.com"/>
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
          <link href='https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap' rel="stylesheet" as="font" />
          <link href='/static/favicon.png' rel='shortcut icon' type='image/x-icon' />
          <link href='/images/webclip.png' rel='apple-touch-icon' />
          <link rel='icon' href='/favicon.ico' />
        </Head>
        <DefaultSeo {...SEO} />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </GoogleReCaptchaProvider>
  )
}

export default withRedux(initStore)(withReduxSaga(appWithTranslation(EvanoApp)))
