import { call, put, takeLatest } from 'redux-saga/effects'
import { Cookies } from 'react-cookie'
import * as actionTypes from '../../constants/index'
import { fetchJSON, tokenExpireTime } from '../../utils'

const cookies = new Cookies()

/* eslint-disable */
function * authorize ({ payload: { email, password, captcha_token } }) {
  const requestData = {
    payload: { email, password },
    method: 'post'
  }
  if (captcha_token) {
    requestData.payload.captcha_token = captcha_token
  }

  try {
    const { data } = yield call(fetchJSON, '/api-token-auth/', requestData)
    yield put({ type: actionTypes.AUTH_SUCCESS, payload: data.token })
    if (process.browser) {
      cookies.set('token', data.token, {
        path: '/',
        expires: tokenExpireTime(data.token)
      })
    }
  } catch (error) {
    let message
    switch (error.status) {
      case 500: message = 'Internal Server Error'; break
      case 401: message = 'Invalid credentials'; break
      default: message = error.data && error.data.message || 'Something went wrong'
    }
    yield put({ type: actionTypes.AUTH_FAILURE, payload: message })

    if (process.browser) {
      cookies.remove('token', { path: '/' })
    }
  }
}

function * auth () {
  yield takeLatest(actionTypes.AUTH_REQUEST, authorize)
}

export default auth
