import { combineReducers } from 'redux'
import auth from './auth'
import language from './language'
import user from './user'
import paymentSettings from './payment_settings'
import formats from './formats'
import activity from './activity'
import contact from './contact'
import fileList from './file_list'
import drive from './drive'

const rootReducer = combineReducers({
  auth: auth.reducer,
  language: language.reducer,
  drive: drive.reducer,
  user,
  paymentSettings,
  formats,
  activity,
  contact,
  fileList,
})

const InitialState = {
  auth: auth.initialState,
  language: language.initialState,
  drive: drive.initialState
}
export { rootReducer, InitialState }
