import * as actionTypes from '../../constants/index'

const initialState = {
  data: [],
  usage: null,
  error: null,
  isFetching: false,
  statistic: null,
  isGettingStatistics: false,
  isFetchingMediaConverter: false,
  isDeletingMediaConverter: false,
  deleteMediaConverterResult: null,
  mediaConvertFile: [],
  total: 0,
  fileCompressInfo: null,
  sessionConvert: ''
}

const fileListReducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case actionTypes.SET_FILE_LIST: {
      return { ...state, data: payload }
    }

    case actionTypes.UPDATE_SETTING_FILE_LIST: {
      const newState = {
        ...state, data: [...state.data.map(item => {
            if (item.uid === payload.fileId) {
              item.settings  = payload.settings
            }
            return item
          }
        )]
      }
      return newState
    }

    case actionTypes.UPDATE_TIME_TRIM_FOR_FILES: {
      console.log('payload.endTrim', payload.endTrim)
      return {
        ...state, data: [...state.data.map(item => item.uid === payload.fileId ? ({
          ...item,
          startTrim: payload.startTrim,
          endTrim: payload.endTrim
        }) : item
        )]
      }
    }

    case actionTypes.DELETE_FILE: {
      const file = payload
      let fileList = state.data
      let index = -1
      fileList.forEach((item, fileIndex) => {
        if ((item.isUrl || item.isDrive) && (item.id === file.id)) {
          index = fileIndex
        } else if (item.uid && file.uid && item.uid === file.uid) {
          index = fileIndex
        }
      })
      if (index !== -1) {
        const newFileList = fileList.slice()
        newFileList.splice(index, 1)
        return { ...state, data: newFileList }
      }
      return state
    }

    case actionTypes.FETCH_USAGE_REQUEST: {
      return { ...state, isFetching: true }
    }
    case actionTypes.FETCH_USAGE_SUCCESS: {
      return { ...state, isFetching: false, usage: payload }
    }
    case actionTypes.FETCH_USAGE_FAILURE: {
      return { ...state, isFetching: false, error }
    }

    case actionTypes.FETCH_STATISTICS_REQUEST: {
      return { ...state, isGettingStatistics: true }
    }
    case actionTypes.FETCH_STATISTICS_SUCCESS: {
      return { ...state, isGettingStatistics: false, statistic: payload }
    }
    case actionTypes.FETCH_STATISTICS_FAILURE: {
      return { ...state, isGettingStatistics: false, error }
    }

    case actionTypes.FETCH_MEDIA_CONVERTER_REQUEST: {
      return { ...state, isFetchingMediaConverter: true }
    }
    case actionTypes.SET_SESSION_CONVERT: {
      return {...state, sessionConvert: payload}
    }
    case actionTypes.FETCH_MEDIA_CONVERTER_SUCCESS: {
      return {
        ...state,
        isFetchingMediaConverter: false,
        mediaConvertFile: [...payload.results],
        total: payload.count
      }
    }
    case actionTypes.FETCH_MEDIA_CONVERTER_FAILURE: {
      return { ...state, isFetchingMediaConverter: false, error }
    }

    case actionTypes.DELETE_MEDIA_CONVERTER: {
      return { ...state, isDeletingMediaConverter: true }
    }

    case actionTypes.DELETE_MEDIA_CONVERTER_SUCCESS: {
      return { ...state, isDeletingMediaConverter: false, deleteMediaConverterResult: payload }
    }

    case actionTypes.DELETE_MEDIA_CONVERTER_FAILURE: {
      return {
        ...state, isDeletingMediaConverter: false, error
      }
    }

    case actionTypes.SAVE_COMPRESS_FILE: {
      return { ...state, fileCompressInfo: payload }
    }

    case actionTypes.RESET_FILE_LIST: {
      return { ...state, data: [] }
    }

    default:
      return state
  }
}

export default fileListReducer
