import * as actionTypes from '../../constants/index'

const initialState = {
  connectStatus: null,
  connectMessage: ''
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CONNECT_DRIVE_SUCCESS: {
      return { ...state, ...payload }
    }
    case actionTypes.CONNECT_DRIVE_FAILURE: {
      return { ...state, ...payload }
    }
    case actionTypes.INIT_DRIVE : {
      return initialState
    }
    default:
      return state
  }
}

export default { reducer, initialState }
