import * as actionTypes from '../../constants/index'

const initialState = {
  // eslint-disable-next-line
  languageCode: process.browser ? document.cookie.replace(/(?:(?:^|.*;\s*)Accept-Language\s*\=\s*([^;]*).*$)|^.*$/, '$1') : 'en',
  error: null
}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CHANGE_LANGUAGE: {
      return { ...state, languageCode: payload }
    }
    default:
      return state
  }
}

export default { reducer, initialState }
