import * as actionTypes from '../../constants/index'

export const fetchActivity = (payload) => ({
  type: actionTypes.FETCH_ACTIVITY_REQUEST,
  payload
})

export const fetchActivitySuccess = (payload) => ({
  type: actionTypes.FETCH_ACTIVITY_SUCCESS,
  payload
})

export const fetchActivityFail = (error) => ({
  type: actionTypes.FETCH_ACTIVITY_FAIL,
  error
})
