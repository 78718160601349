import { call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../../constants/index'
import { fetchJSON } from '../../utils'

import {
  deletePaymentMethodFail,
  deletePaymentMethodSuccess,
  fetchPaymentHistoryFail,
  fetchPaymentHistorySuccess,
  fetchPaymentListFail,
  fetchPaymentListSuccess,
  makeDefaultPmtMethodFail,
  makeDefaultPmtMethodSuccess
} from '../actions/payment_settings'

function * fetchPaymentHistory ({ data }) {
  const requestData = {
    method: 'get'
  }

  const { page } = data
  try {
    const { data } = yield call(fetchJSON, `/transactions/?page=${page}`, requestData)
    yield put(fetchPaymentHistorySuccess(data))
  } catch (error) {
    yield put(fetchPaymentHistoryFail(error.data))
  }
}

function * fetchPaymentList () {
  const requestData = {
    method: 'get'
  }

  try {
    const { data } = yield call(fetchJSON, '/payments/', requestData)
    yield put(fetchPaymentListSuccess(data.results))
  } catch (error) {
    yield put(fetchPaymentListFail(error.data))
  }
}

function * makeDefaultPaymentMethod ({ payload }) {
  const { paymentId } = payload
  const requestData = {
    payload: { paymentId },
    method: 'post'
  }

  try {
    const { data } = yield call(fetchJSON, `/payments/${paymentId}/make-default/`, requestData)
    yield put(makeDefaultPmtMethodSuccess(data))
  } catch (error) {
    yield put(makeDefaultPmtMethodFail(error.data))
  }
}

function * deletePaymentMethod ({ payload }) {
  const { paymentId } = payload
  const requestData = {
    payload: { paymentId },
    method: 'delete'
  }

  try {
    const { data } = yield call(fetchJSON, `/payments/${paymentId}/`, requestData)
    yield put(deletePaymentMethodSuccess(data))
  } catch (error) {
    yield put(deletePaymentMethodFail(error.data))
  }
}

function * watchFetchPaymentHistory () {
  yield takeLatest(actionTypes.FETCH_PAYMENT_HISTORY_REQUEST, fetchPaymentHistory)
}

function * watchFetchPaymentList () {
  yield takeLatest(actionTypes.FETCH_PAYMENT_LIST_REQUEST, fetchPaymentList)
}

function * watchMakeDefaultPaymentMethod () {
  yield takeLatest(actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_REQUEST, makeDefaultPaymentMethod)
}

function * watchDeletePaymentMethod () {
  yield takeLatest(actionTypes.DELETE_PAYMENT_METHOD_REQUEST, deletePaymentMethod)
}

export { watchFetchPaymentHistory, watchFetchPaymentList, watchMakeDefaultPaymentMethod, watchDeletePaymentMethod }
