import * as actionTypes from '../../constants/index'

const initialState = {
  isUpdating: false,
  user: {},
  error: null,
  isFetching: false,
  isDeleting: false,
  isGettingAccountSocials: false,
  socialAccounts: [],
  isDeletingSocialAccount: false,
  isUpdatingEmail: false,
  deleteSocialAccountRes: null,
  isFetchingCloudStorage: false,
  cloudStorages: []
}

const userReducer = (state = initialState, { payload, error, type }) => {
  switch (type) {
    // get user info
    case actionTypes.FETCH_USER_REQUEST: {
      return { ...state, isFetching: true }
    }
    case actionTypes.FETCH_USER_SUCCESS: {
      return { ...state, user: payload, isFetching: false }
    }
    case actionTypes.FETCH_USER_FAIL: {
      return { ...state, error, isFetching: false }
    }
    // update user password
    case actionTypes.UPDATE_USER_PASSWORD_REQUEST: {
      return { ...state, isUpdating: true }
    }
    case actionTypes.UPDATE_USER_PASSWORD_SUCCESS: {
      return { ...state, user: { ...state.user, updatePassword: payload }, isUpdating: false, error: null }
    }
    case actionTypes.UPDATE_USER_PASSWORD_FAIL: {
      return { ...state, error, isUpdating: false }
    }
    // update user name
    case actionTypes.UPDATE_USER_NAME_REQUEST:
      return { ...state, isUpdating: true }
    case actionTypes.UPDATE_USER_NAME_SUCCESS:
      return { ...state, user: payload, isUpdating: false }
    case actionTypes.UPDATE_USER_NAME_FAIL:
      return { ...state, error, isUpdating: false }
    // update user email
    case actionTypes.UPDATE_USER_EMAIL_REQUEST:
      return { ...state, isUpdatingEmail: true }
    case actionTypes.UPDATE_USER_EMAIL_SUCCESS:
      return { ...state, user: payload, isUpdatingEmail: false }
    case actionTypes.UPDATE_USER_EMAIL_FAIL:
      return { ...state, error, isUpdatingEmail: false }
    // delete user account
    case actionTypes.DELETE_USER_ACCOUNT_REQUEST:
      return { ...state, isDeleting: true }
    case actionTypes.DELETE_USER_ACCOUNT_SUCCESS:
      return { ...state, isDeleting: false, user: { ...state.user, deleteAccount: payload } }
    case actionTypes.DELETE_USER_ACCOUNT_FAIL:
      return { ...state, isDeleting: false, error }
    // get social account
    case actionTypes.GET_ACCOUNT_SOCIALS_REQUEST:
      return { ...state, isGettingAccountSocials: true }
    case actionTypes.GET_ACCOUNT_SOCIALS_SUCCESS:
      return { ...state, isGettingAccountSocials: false, socialAccounts: payload }
    case actionTypes.GET_ACCOUNT_SOCIALS_FAIL:
      return { ...state, isGettingAccountSocials: false, error }
    // delete social account
    case actionTypes.DELETE_SOCIAL_ACCOUNT_REQUEST:
      return { ...state, isDeletingSocialAccount: true }
    case actionTypes.DELETE_SOCIAL_ACCOUNT_SUCCESS:
      return { ...state, isDeletingSocialAccount: false, deleteSocialAccountRes: payload }
    case actionTypes.DELETE_SOCIAL_ACCOUNT_FAIL:
      return { ...state, isDeletingSocialAccount: false, error }
    // clear user
    case actionTypes.CLEAR_USER_INFO:

      return { ...state, iseClearUser: true, error: null, deleteSocialAccountRes: null }
    // get user info
    case actionTypes.FETCH_CLOUD_STORAGE: {
      return { ...state, isFetchingCloudStorage: true }
    }
    case actionTypes.FETCH_CLOUD_STORAGE_SUCCESS: {
      let storages = []
      if (payload.google_drive && Object.keys(payload.google_drive).length > 0) {
        storages.push({...payload.google_drive, id: 'google_drive', icon: '/images/icon_google_drive.svg', text: 'Google Drive'})
      }
      if (payload.drop_box && Object.keys(payload.drop_box).length > 0) {
        storages.push({...payload.google_drive, id: 'drop_box', icon: '/images/icon_dropbox.svg', text: 'Dropbox'})
      }
      if (process.browser) {
        localStorage.setItem('__cloud', JSON.stringify(storages))
      }
      return { ...state, cloudStorages: storages, isFetchingCloudStorage: false }
    }
    case actionTypes.FETCH_CLOUD_STORAGE_FAILURE: {
      return { ...state, error, isFetchingCloudStorage: false }
    }
    default:
      return state
  }
}

export default userReducer
