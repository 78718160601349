import * as actionTypes from '../../constants/index'

const initialState = {
  data: {},
  error: null,
  requesting: false
}

const contactReducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case actionTypes.CONTACT_US_REQUEST: {
      return { ...state, requesting: true }
    }
    case actionTypes.CONTACT_US_SUCCESS: {
      return { ...state, requesting: false, data: payload, error: null }
    }
    case actionTypes.CONTACT_US_FAILURE: {
      return { ...state, requesting: false, error }
    }
    default:
      return state
  }
}

export default contactReducer
