import * as actionTypes from '../../constants/index'

export const requestContactUs = (payload) => ({
  type: actionTypes.CONTACT_US_REQUEST,
  payload: payload
})

export const requestContactUsSuccess = (payload) => ({
  type: actionTypes.CONTACT_US_SUCCESS,
  payload: payload
})

export const requestContactUsFailure = (error) => ({
  type: actionTypes.CONTACT_US_FAILURE,
  error
})
