import auth from './auth'
import {
  watchDeleteSocialAccount,
  watchDeleteUserAccount,
  watchFetchCloudStorage,
  watchGetSocialAccount,
  watchUpdateUserEmail,
  watchUpdateUserName,
  watchUpdateUserPassword
} from './user'
import {
  watchDeletePaymentMethod,
  watchFetchPaymentHistory,
  watchFetchPaymentList,
  watchMakeDefaultPaymentMethod
} from './payment_settings'
import { all, fork } from 'redux-saga/effects'
import { watchFetchActivity } from './activity'
import { watchRequestContactUs } from './contact'
import { watchDeleteMediaConverter, watchFetchStatistics, watchFetchUsage, watchGetMediaConverter } from './file'

export default function * rootSaga () {
  yield all([
    fork(auth),
    watchUpdateUserPassword(),
    watchUpdateUserName(),
    watchDeleteUserAccount(),
    watchFetchPaymentHistory(),
    watchFetchPaymentList(),
    watchMakeDefaultPaymentMethod(),
    watchDeletePaymentMethod(),
    watchGetSocialAccount(),
    watchDeleteSocialAccount(),
    watchUpdateUserEmail(),
    watchFetchActivity(),
    watchRequestContactUs(),
    watchFetchUsage(),
    watchFetchStatistics(),
    watchGetMediaConverter(),
    watchFetchCloudStorage(),
    watchDeleteMediaConverter()
  ])
}
