export default {
  titleTemplate: '%s - Evano',
  description: 'World\'s Best Video And Audio Editing Software Online',
  openGraph: {
    title: 'World\'s Best Video And Audio Editing Software Online',
    type: 'website',
    locale: 'en_IE',
    url: 'https://evano.com/',
    site_name: 'Evano',
    images: [
      { url: '/images/thumbnail.png?url' }
    ]
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image'
  }
}
