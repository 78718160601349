import * as actionTypes from '../../constants/index'

const initialState = {
  logs: [],
  error: null,
  isFetching: false,
  total: 1
}

const activityReducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case actionTypes.FETCH_ACTIVITY_REQUEST: {
      return { ...state, isFetching: true }
    }
    case actionTypes.FETCH_ACTIVITY_SUCCESS: {
      return { ...state, isFetching: false, logs: payload.results, total: payload.count }
    }
    case actionTypes.FETCH_ACTIVITY_FAIL: {
      return { ...state, isFetching: false, error }
    }
    default:
      return state
  }
}

export default activityReducer
