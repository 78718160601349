import { call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../../constants/index'
import { fetchJSON } from '../../utils'
import { fetchActivityFail, fetchActivitySuccess } from '../actions/activity'

function * fetchActivity ({ payload }) {
  const { userId, page } = payload
  const requestData = {
    method: 'get'
  }

  try {
    const { data } = yield call(
      fetchJSON,
      `/user-profile/${userId}/activities/?page=${page}`,
      requestData
    )

    yield put(fetchActivitySuccess(data))
  } catch (error) {
    yield put(fetchActivityFail(error.data))
  }
}

function * watchFetchActivity () {
  yield takeLatest(
    actionTypes.FETCH_ACTIVITY_REQUEST,
    fetchActivity
  )
}

export { watchFetchActivity }
