// ACTIONS
import * as actionTypes from '../../constants/index'

export const setSessionConvert = (payload) => ({
  type: actionTypes.SET_SESSION_CONVERT,
  payload
})

export const setList = (payload) => ({
  type: actionTypes.SET_FILE_LIST,
  payload
})

export const updateSettingFileList = (payload) => ({
  type: actionTypes.UPDATE_SETTING_FILE_LIST,
  payload
})

export const updateTimeTrimForFiles = (payload) => console.log('payload', payload) || ({
  type: actionTypes.UPDATE_TIME_TRIM_FOR_FILES,
  payload
})

export const deleteFile = (payload) => ({
  type: actionTypes.DELETE_FILE,
  payload
})

export const fetchUsageInfo = () => ({
  type: actionTypes.FETCH_USAGE_REQUEST
})

export const fetchUsageInfoSuccess = (payload) => ({
  type: actionTypes.FETCH_USAGE_SUCCESS,
  payload
})

export const fetchUsageInfoFailure = (error) => ({
  type: actionTypes.FETCH_USAGE_FAILURE,
  error
})

export const fetchStatistics = () => ({
  type: actionTypes.FETCH_STATISTICS_REQUEST
})

export const fetchStatisticsSuccess = (payload) => ({
  type: actionTypes.FETCH_STATISTICS_SUCCESS,
  payload
})

export const fetchStatisticsFailure = (error) => ({
  type: actionTypes.FETCH_STATISTICS_FAILURE,
  error
})

export const getMediaConverter = (payload) => ({
  type: actionTypes.FETCH_MEDIA_CONVERTER_REQUEST,
  payload
})

export const getMediaConverterSuccess = (payload) => ({
  type: actionTypes.FETCH_MEDIA_CONVERTER_SUCCESS,
  payload
})

export const getMediaConverterFailure = (error) => ({
  type: actionTypes.FETCH_MEDIA_CONVERTER_FAILURE,
  error
})

export const saveCompressFile = (payload) => ({
  type: actionTypes.SAVE_COMPRESS_FILE,
  payload
})

export const resetFileList = () => ({
  type: actionTypes.RESET_FILE_LIST
})


export const deleteMediaConverter = (payload) => ({
  type: actionTypes.DELETE_MEDIA_CONVERTER,
  payload
})

export const deleteMediaConverterSuccess = (payload) => ({
  type: actionTypes.DELETE_MEDIA_CONVERTER_SUCCESS,
  payload
})

export const deleteMediaConverterFailure = (error) => ({
  type: actionTypes.DELETE_MEDIA_CONVERTER_FAILURE,
  error
})