import * as actionTypes from '../../constants/index'

const initialState = {
  error: null,
  isFetching: false,
  isGettingListPayment: false,
  data: [],
  listPayments: [],
  makingDefaultPaymentMethod: false,
  results: {},
  isDeleting: false,
  total: 1
}

const paymentSettingsReducer = (state = initialState, { data, error, type }) => {
  switch (type) {
    // fetch payment history
    case actionTypes.FETCH_PAYMENT_HISTORY_REQUEST: {
      return { ...state, isFetching: true }
    }
    case actionTypes.FETCH_PAYMENT_HISTORY_SUCCESS: {
      return { ...state, isFetching: false, data: data.results, total: data.count }
    }
    case actionTypes.FETCH_PAYMENT_HISTORY_FAIL: {
      return { ...state, isFetching: false, error }
    }

    // fetch payment list
    case actionTypes.FETCH_PAYMENT_LIST_REQUEST: {
      return { ...state, isGettingListPayment: true }
    }
    case actionTypes.FETCH_PAYMENT_LIST_SUCCESS: {
      return { ...state, isGettingListPayment: false, listPayments: data }
    }
    case actionTypes.FETCH_PAYMENT_LIST_FAIL: {
      return { ...state, isGettingListPayment: false, error }
    }
    // make default payment method
    case actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_REQUEST: {
      return { ...state, makingDefaultPaymentMethod: true }
    }
    case actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS: {
      return { ...state, makingDefaultPaymentMethod: false, results: { ...state.results, ...data } }
    }
    case actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_FAIL: {
      return { ...state, makingDefaultPaymentMethod: false, error }
    }
    // delete payment method
    case actionTypes.DELETE_PAYMENT_METHOD_REQUEST: {
      return { ...state, isDeleting: true }
    }
    case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS: {
      return { ...state, isDeleting: false, results: { ...state.results, ...data } }
    }
    case actionTypes.DELETE_PAYMENT_METHOD_FAIL: {
      return { ...state, isDeleting: false, error }
    }

    default:
      return state
  }
}

export default paymentSettingsReducer
