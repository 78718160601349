import { Cookies } from 'react-cookie'
import * as actionTypes from '../../constants/index'

const cookies = new Cookies()
const initialState = {
  token: process.browser ? cookies.get('token') : null,
  error: null
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.AUTH_SUCCESS: {
      return { ...state, token: payload }
    }
    case actionTypes.AUTH_FAILURE: {
      if (initialState.token || state.token) {
        cookies.remove('token', { path: '/' })
        /* global localStorage */
        if (localStorage) {
          localStorage.removeItem('__uzz')
        }
        if (process.browser) {
          window.location = '/'
        }
        /* eslint-disable */
        return { ...state, error: payload }
      }
      return { ...state, error: payload }
    }
    default:
      return state
  }
}

export default { reducer, initialState }
