import * as actionTypes from '../../constants/index'

export const fetchPaymentHistory = (data) => ({
  type: actionTypes.FETCH_PAYMENT_HISTORY_REQUEST,
  data
})

export const fetchPaymentHistorySuccess = (data) => ({
  type: actionTypes.FETCH_PAYMENT_HISTORY_SUCCESS,
  data
})

export const fetchPaymentHistoryFail = () => ({
  type: actionTypes.FETCH_PAYMENT_HISTORY_FAIL
})

export const fetchPaymentList = () => ({
  type: actionTypes.FETCH_PAYMENT_LIST_REQUEST
})

export const fetchPaymentListSuccess = (data) => ({
  type: actionTypes.FETCH_PAYMENT_LIST_SUCCESS,
  data
})

export const fetchPaymentListFail = () => ({
  type: actionTypes.FETCH_PAYMENT_LIST_FAIL
})

export const makeDefaultPmtMethod = (payload) => ({
  type: actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_REQUEST,
  payload
})

export const makeDefaultPmtMethodSuccess = (data) => ({
  type: actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_SUCCESS,
  data
})

export const makeDefaultPmtMethodFail = (error) => ({
  type: actionTypes.MAKE_DEFAULT_PAYMENT_METHOD_FAIL,
  error
})

export const deletePaymentMethod = (payload) => ({
  type: actionTypes.DELETE_PAYMENT_METHOD_REQUEST,
  payload
})
export const deletePaymentMethodSuccess = (data) => ({
  type: actionTypes.DELETE_PAYMENT_METHOD_SUCCESS,
  data
})
export const deletePaymentMethodFail = (error) => ({
  type: actionTypes.DELETE_PAYMENT_METHOD_FAIL,
  error
})
